import React from "react";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Container, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  getAllFleetName, fetchFleetVehiclebyOperator, createFleetOwnerAction
} from "../../../../store/actions/fleetAction";
import "../../reacttable/reactTable.css";
import Table from "../../reacttable/table";
import Popup from "reactjs-popup";
import moment from "moment";
import { ImCross } from "react-icons/im";
import { allOrgTariffAction } from "../../../../store/actions/organisationAction";
import { Redirect } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";

function FleetOnBoardingCard(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.all_fleet_name();
    props.vehicle_byoperator();
    props.allOrg();

  }, []);

  const data = props.organisationlist && props.organisationlist.organisations;
  const totalFleet = props.fleet_list && props.fleet_list.data;
  // console.log("data",data)
  const isFleetOrg = data && data.filter((item) => item.isfleet === "Yes")
  // console.log("isFleetOrg",isFleetOrg)

  const ownerSchema = Yup.object().shape({
    ownername: Yup.string().required("Fleet Owner name is required"),
    organisations: Yup.string().required('Organisation is required'),
    status: Yup.string()
  });

  const { register, handleSubmit, formState: { errors }, } = useForm({ resolver: yupResolver(ownerSchema) });

  const onSubmit = async (data) => {
    props.save_owner(data);
    // console.log(data);
  };

  const handleRedirect = () => {
    return <Redirect to="/dashboard/save_vehicle" />;
  }

  // if (props.fleet_owner.statuscode === 200) {
  //   alert("Fleet member has been successfully registered");
  //   window.location.reload();
  // }

  useEffect(() => {
    if (props.fleet_owner.statuscode === 200) {
      toast.success('Fleet member has been successfully registered', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => window.location.reload(),
      });
    }
  }, [props.fleet_owner]);

  // console.log(props.fleet.save_owner);
  const memberList = props.fleet_list && props.fleet_list.data;
  // console.log("memberList", memberList);
  const columns = useMemo(
    () => [
      {
        Header: "Owner Name",
        accessor: "ownername",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Operator",
        accessor: "organisation",
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      // },
    ],
    []
  );
  const vehicleData = props.vehicle_list && props.vehicle_list.data
  // console.log(vehicleData);
  const Vehicle_columns = useMemo(
    () => [
      {
        Header: "Owner Name",
        accessor: "v_owner",
      },
      {
        Header: "Vin",
        accessor: "vin",
      },
      {
        Header: "Vehicle Model",
        accessor: "v_model",
      },
      {
        Header: "Vehicle Type",
        accessor: "v_type",
      },
      {
        Header: "Charging Priority",
        accessor: "charging_priority",
      },
      {
        Header: "Manufacturer Name",
        accessor: "manufacturer_name",
      },
      {
        Header: "Registration Date",
        accessor: "registration_date",
        Cell: ({ value }) => {
          return <span>{moment(value).format("DD-MM-YYYY")}</span>;
        },
      },
      {
        Header: "Created Date",
        accessor: "createdat",
        Cell: ({ value }) => {
          return <span>{moment(value).format("DD-MM-YYYY")}</span>;
        },
      },
    ],
    []
  );
  return (
    <Container fluid>

      <PureBreadcrumbs />
      <br />

      <Row className="mt-3">
        <Col xs={12} md={4}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>
                TOTAL NUMBER OF FLEET
              </Card.Text>
              <Card.Title>
                <b>
                  {totalFleet ? totalFleet.length : 0}
                </b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/fleet/car1blue.svg"}
                    className="fleet-ico1"
                    alt=""
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={4}>
          </Col>
        {/* <Col xs={12} md={4}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>
                TOTAL NUMBER OF VEHICLES
              </Card.Text>
              <Card.Title>
                <b>{vehicleData ? vehicleData.length : 0}</b>
              </Card.Title>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/fleet/car2green.svg"}
                    className="fleet-ico1"
                    alt=""
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col> */}
        <Col xs={12} md={4} className="third-column me-2">
          <Row className="" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Popup
              trigger={
                <button
                  className="lgn-btn btn"
                  type="button"
                  variant="primary"
                  style={{ marginRight: "10px", }}
                >
                  Onboard fleet member
                </button>
              }
              modal
              contentStyle={{
                borderRadius: "10px",
                padding: "20px",
                width: "50%",
                margin: "auto",
              }}
              className="popup-content"
            >
              {(close) => (

                <>
                  <ToastContainer
                    position="top-center"
                    autoClose={500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    style={{ top: "10%", width: "auto" }}
                  />
                  <div style={{ textAlign: "right" }}>
                    <button className="close-btn" onClick={close}>
                      <span><ImCross></ImCross></span>
                    </button>
                  </div>
                  <div>
                    <h2 style={{ textAlign: "center" }}>Onboard Fleet Member</h2>
                    <form className="p-3" onSubmit={e => e.preventDefault()}>
                      <div className="form-floating">
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.ownername ? "is-invalid" : ""
                            }`}
                          name="ownername"
                          id="floatingInputGridname"
                          placeholder="Fleet Owner Name"
                          {...register("ownername")}
                        />
                        <div className="invalid-feedback">
                          {errors.ownername?.message}
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-6 col-sm-12">
                        <div className="form-floating" >
                          <select className={`form-control form-select selectOrg ${errors.organisations ? 'is-invalid' : ''}`} name="unit" id="floatingSelectGridorganisation"  {...register("organisations")}>
                            <option value="">Select Organisation</option>
                            {isFleetOrg && isFleetOrg.map((v, k) =>
                              <option key={v.name} value={v.name}>{v.name}</option>
                            )}
                          </select>
                          <div className="invalid-feedback">{errors.organisations?.message}</div>
                        </div>
                      </div>
                      <br />
                      <div className="popup-btn">
                        {props.loading ?
                          <button className="btn lgn-btn second-button" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            &nbsp;
                            {t("Saving...")}
                          </button>
                          :
                          <button
                            className="lgn-btn second-button"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                          >
                            Save
                          </button>
                        }
                      </div>
                      <br />
                    </form>
                  </div>
                </>
              )}
            </Popup>
          </Row>
          <br />
          <br />
          <Row className=" pb-2" style={{ display: "flex", justifyContent: "flex-end" }}>
            <a
              href={`/dashboard/save_vehicle`}
              className="btn lgn-btn"
              type="button"
              onClick={handleRedirect}
              style={{ display: "flex", justifyContent: "center", alignItems: "center", textDecoration: 'none', marginRight: "10px" }}
            >
              <p className="" style={{ fontSize: "17px", margin: 0 }}>Add Vehicle Charging Priority</p>
            </a>
          </Row>
        </Col>
      </Row>
      <br />
      <Row className="p-3">
        <Col xs={12}>
          <Row>
            <Card className="customercard table">
              <div className="table-header">Fleet Member List</div>
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
              }}>Loading data...</h3> :
                memberList && memberList.length > 0 ? (
                  <div>
                    <Table
                      data={memberList}
                      columns={columns}
                      style={{ width: "100%" }}
                    ></Table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3 className="loader">No data found</h3>
                  </div>
                )}
            </Card>
          </Row>
        </Col>
      </Row>
      <Row className="p-3">
        <Col xs={12}>
          <Row>
            <Card className="customercard table">
              <div className="table-header">Charging Vehicle Priority List</div>
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
              }}>Loading data...</h3> :
                vehicleData && vehicleData.length > 0 ? (
                  <div>
                    <Table
                      data={vehicleData}
                      columns={Vehicle_columns}
                      style={{ width: "100%" }}
                    ></Table>

                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3 className="loader">No data found</h3>
                  </div>
                )}
            </Card>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.campaign.isLoading,
    fleet_list: state.fleet.all_fleet_name,
    vehicle_list: state.fleet.vehicle_byoperator,
    fleet_owner: state.fleet.save_owner,
    organisationlist: state.operator.organisationsList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all_fleet_name: () => dispatch(getAllFleetName()),
    vehicle_byoperator: () => dispatch(fetchFleetVehiclebyOperator()),
    save_owner: (data) => dispatch(createFleetOwnerAction(data)),
    allOrg: () => dispatch(allOrgTariffAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FleetOnBoardingCard);
