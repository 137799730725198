import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import ApexCharts from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchCityComparisionAction } from "../../../../store/actions/insightAction";
import { allEvseAction } from "../../../../store/actions/evseAction";
import { useTranslation } from "react-i18next";

function CityComparisionGraph(props) {
  // Chart.register(...registerables);
  const currency = process.env.REACT_APP_CURRENCY;
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 3600 * 1000 * 720)
  );
  const [endDate, setEndDate] = useState(
    new Date()
  );
  useEffect(() => {
    props.all();
  }, []);

  const cityFilter = props.list && props.list.data?.map((ct) => ct.city);
  // console.log("city", props.city.data1 && props.city.data1);
  var uniqueCity =
    cityFilter &&
    cityFilter.filter((value, index, array) => array.indexOf(value) === index);
  var myCityArray =
    uniqueCity &&
    uniqueCity.map((str, index) => ({ value: str, id: index + 1 }));
  // console.log(myCityArray);

  const [city1, setCity1] = useState(null);
  const [city2, setCity2] = useState(null);

  const handleCity1Change = (e) => {
    const data = e.target.value;
    setCity1(data);
  };
  const handleCity2Change = (e) => {
    const data = e.target.value;
    // console.log(data)
    setCity2(data);
  };

  const onSubmit = () => {
    // console.log("city1", city1,city2)
    if (city1 == null) {
      alert("Select City 1")
    } if (city2 == null) {
      alert("Select City 2")
    }
    props.citycompare(city1, city2, startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10));
  };


  const series1 = [
    {
      name: "Total Energy",
      data: props.city.data1 && props.city.data1.map((en) => en.consumption),
    },
    {
      name: "Total Revenue",
      data: props.city.data1 && props.city.data1.map((en) => en.amount),
    },
  ];

  const series2 = [
    {
      name: "Total Energy",
      data: props.city.data2 && props.city.data2.map((en) => en.consumption),
    },
    {
      name: "Total Revenue",
      data: props.city.data2 && props.city.data2.map((en) => en.amount),
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: props.city.data1 && props.city.data1.map((en) => en._id),
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        title: {
          text: "Total Energy",
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
      },

      {
        opposite: true,
        title: {
          text: "Total Revenue",
        },
        labels: {
          formatter: function (value) {
            return `${currency} ${value.toFixed(2)}`;
          },
        },
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#5559B2", "#0BCF6B"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const options2 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: props.city.data2 && props.city.data2.map((en) => en._id),
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        title: {
          text: "Total Energy",
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
      },

      {
        opposite: true,
        title: {
          text: "Total Revenue",
        },
        labels: {
          formatter: function (value) {
            return `${currency} ${value.toFixed(2)}`;
          },
        },
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#5559B2", "#0BCF6B"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  // console.log("city data1",props.city.data1 && props.city.data1)
  // console.log("city data2",props.city.data2 && props.city.data2)
  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <Card.Header>
              <Row>
                <Col xs={12} md={4} className="pb-2">
                  {t("City Comparison Graph")}
                </Col>
                <Col xs={12} md={12}>
                  <div className="row  float-right">
                    <div className="mb-2 " style={{ width: "auto", paddingRight: "20px" }}>
                      <select style={{ borderRadius: "3px", borderColor: "#a7a6a6" }} onChange={(e) => handleCity1Change(e)} required>
                        <option value={""}>Select City 1</option>
                        {myCityArray &&
                          myCityArray.map((c, i) => (
                            <option key={c.id} value={c.value}>
                              {c.value}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div style={{ width: "auto", paddingRight: "20px" }}>
                      <select style={{ borderRadius: "3px", borderColor: "#a7a6a6" }} onChange={(e) => handleCity2Change(e)} required>
                        <option value={""}>Select City 2</option>
                        {myCityArray &&
                          myCityArray.map((c, i) => (
                            <option key={c.id} value={c.value}>
                              {c.value}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-auto calendar">
                        <DatePicker
                          className="gray-border"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          maxDate={endDate}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                      <span style={{ paddingTop: "7px" }}> To </span>
                      <div className="col-auto mx-2 calendar">
                        <DatePicker
                          className="gray-border"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          maxDate={new Date()}
                          todayButton="Today"
                          showDisabledMonthNavigation
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="csvbutton go-btn ml-5">
                      <button className="btn btn-sm" onClick={onSubmit}>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#676767",
                            fontWeight: "600",
                          }}
                        >
                          GO
                        </span>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={12} md={6}>
                  <Card className="customercard">
                    <Card.Title>
                      <center> {t("City 1")}: {city1} </center>
                      <hr />
                    </Card.Title>
                    {props.cityGraphloading ? <h3 style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "387px",
                    }}> Loading data... </h3> :
                      props.city.data1 && props.city.data1.length > 0 ? (

                        <ApexCharts
                          options={options}
                          series={series1}
                          type="bar"
                          height={300}
                        />) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "300px",
                          }}
                        >
                          <h3>{t("No data found")} </h3>
                        </div>
                      )}
                  </Card>
                </Col>
                <Col sm={12} md={6}>
                  <Card className="customercard">
                    <Card.Title>
                      <center>{t("City 2")}: {city2} </center>
                      <hr />
                    </Card.Title>
                    {props.cityGraphloading ? <h3 style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "387px",
                    }}> Loading data... </h3> :
                      props.city.data2 && props.city.data2.length > 0 ? (

                        <ApexCharts
                          options={options2}
                          series={series2}
                          type="bar"
                          height={300}
                        />) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "300px",
                          }}
                        >
                          <h3>{t("No data found")}</h3>
                        </div>
                      )}
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    cityGraphloading: state.insight.isCityGraphLoading,
    city: state.insight.city_comparision,
    list: state.evse.allevse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allEvseAction()),
    citycompare: (city1, city2, start_date, end_date) => dispatch(fetchCityComparisionAction(city1, city2, start_date, end_date)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CityComparisionGraph);
