import React, { useMemo, useEffect, useState } from "react"
import { connect } from "react-redux";
import { getSingleCustomerGroupingList, allCutomersAction } from "../../../../store/actions/customerActions";
import "../../reacttable/reactTable.css"
import { Col, Row } from "react-bootstrap";
import UpdateCustomerGroupingTable from "./../customerGrouping/updateCustomerGroupTable";
import { useTranslation } from "react-i18next";
import { fetchActiveTariffList } from "../../../../store/actions/tariffAction";
import PureBreadcrumbs from "../../breadcrums";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchAllSubscriptionApi } from "../../../../store/actions/walletActions";
import Select from 'react-select'

function UpdateCustomerGrouping(props) {
    const { id } = useParams();
    const group_name = id;
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    const [subscriptionName, setSubscriptionName] = useState('');
    const [tariffName, setTariffName] = useState('');

    // console.log(id)
    useEffect(() => {
        props.getCustomers();
        props.getActiveTariffList();
        props.singleData(group_name);
        props.fetchAll();
    }, [group_name])

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        if (!e.target.checked) {
            setSubscriptionName('');
        }
    };

    const handleDropdownChange = (e) => {
        setSubscriptionName(e.target.value);
    };

    // const handleSelectTariff = (event) => {
    //     // console.log(event.target.value)
    //     setTariffName(event.target.value);
    // };

    const singleData = props.getSingle && props.getSingle.data && props.getSingle.data[0]
    // console.log("singleData: ", singleData);

    // here we are setting up the previous value of is valid subscription checkbox and subscription name (select input).
    useEffect(() => {
        if (singleData) {
            setIsChecked(singleData.is_valid_subscription);
            setSubscriptionName(singleData.subscription_name);
            setTariffName(singleData.tariff);
        }
    }, [singleData])

    const customer = singleData && singleData.customer
    const data = props.list && props.list.customers;
    // console.log(data);
    const filteredData = data && data.map(item => ({
        firstname: item.firstname,
        lastname: item.lastname,
        email: item.email,
        mobile: item.mobile,
        userid: item.userid,
    }));

    const columns = useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstname'
            },
            {
                Header: 'Last Name',
                accessor: 'lastname'
            },
            {
                Header: 'Email Id',
                accessor: 'email'
            },
            {
                Header: 'User Name',
                accessor: 'userid'
            },
            {
                Header: 'Mobile',
                accessor: 'mobile'
            },

        ],
        []
    );

    const handleTariff = (e) => {
        const code = e.value;
        // console.log("tariff name", code);
        setTariffName(code);
    };

    const options = props.fetchActiveTariffList && props.fetchActiveTariffList.data?.map(item => ({
        value: item.name,
        label: item.name
    }));

    const handleSubscriptionName = (e) => {
        const code = e.value;
        // console.log("tariff name", code);
        setSubscriptionName(code);
    };

    const subNameOptions = props.fetchAllRes && props.fetchAllRes.data?.map(item => ({
        value: item.sub_name,
        label: item.sub_name
    }));

    return (

        <div className="container-fluid pt-2">
            <PureBreadcrumbs />
            <Row className="">
                <Col lg={12} className="">
                    <div className="customercard mt-2">
                        <div className=""><h4>{t("Update Customer Grouping")}</h4></div>
                        <Row className="mt-3">
                            <Col className="col-lg-6">
                                <div>
                                    <label>{t("Group Name")} <span className='mandatory-field'>*</span></label>
                                    <input
                                        type="text"
                                        disabled
                                        className="form-control gray-border"
                                        value={singleData?.group_name || ''}
                                        placeholder="Group Name"
                                    />
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <div>
                                    <div className="">
                                        <label>{t("Select Tariff")} <span className='mandatory-field'>*</span></label>
                                        <Select
                                            options={options ? options : []}
                                            // className={`${errors.station_name ? 'is-invalid' : ''}`}
                                            name="tariff"
                                            id="floatingInputGridTariff"
                                            placeholder={tariffName ? tariffName : "Select Tariff Name"}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? 'grey' : 'grey',
                                                    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                    height: 'calc(1.5em + 0.75rem + 2px)',
                                                    minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                                }),
                                                valueContainer: (baseStyles) => ({
                                                    ...baseStyles,
                                                    height: 'calc(1.5em + 0.75rem + 2px)',
                                                    padding: '0 0.75rem',
                                                }),
                                                placeholder: (baseStyles) => ({
                                                    ...baseStyles,
                                                    whiteSpace: 'nowrap',
                                                }),
                                                input: base => ({
                                                    ...base,
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    '& input': {
                                                        border: 'none !important',
                                                    },
                                                }),

                                            }}
                                            onChange={handleTariff}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="mt-3 pl-0 d-flex align-items-center">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        id="subscriptionValid"
                                        style={{ left: "10rem" }}
                                    />
                                    <label className="form-check-label" htmlFor="subscriptionValid">
                                        {t("Is subscription valid ?")}
                                    </label>
                                </div>
                            </Col>
                            <Col lg={6} className="mt-1">
                                {isChecked && (
                                    <div>
                                        <label htmlFor="subscriptionName" className="mt-2 form-label">
                                            {t("Select Subscription Name")}
                                        </label>
                                        <Select
                                            options={subNameOptions ? subNameOptions : []}
                                            name="subscriptionName"
                                            id="floatingInputGridSubscriptionName"
                                            placeholder={subscriptionName ? subscriptionName : "Select Subscription Name"}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? 'grey' : 'grey',
                                                    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                    height: 'calc(1.5em + 0.75rem + 2px)',
                                                    minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                                }),
                                                valueContainer: (baseStyles) => ({
                                                    ...baseStyles,
                                                    height: 'calc(1.5em + 0.75rem + 2px)',
                                                    padding: '0 0.75rem',
                                                }),
                                                placeholder: (baseStyles) => ({
                                                    ...baseStyles,
                                                    whiteSpace: 'nowrap',
                                                }),
                                                input: base => ({
                                                    ...base,
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    '& input': {
                                                        border: 'none !important',
                                                    },
                                                }),

                                            }}
                                            onChange={handleSubscriptionName}
                                        />
                                    </div>
                                )}
                            </Col>
                        </Row>

                        {props.loading ? <h3 style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "250px",
                        }}>Loading data...</h3> :
                            data && data.length > 0 ?
                                (<div> <UpdateCustomerGroupingTable {...props} isChecked={isChecked} sub_name={subscriptionName} filteredAPIData={customer} tariffName={tariffName || singleData?.tariff} groupName={singleData?.group_name} data={filteredData} columns={columns}></UpdateCustomerGroupingTable></div>)
                                :
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
                        }
                    </div>

                </Col>
            </Row>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        list: state.customer.fetchcutomers,
        fetchActiveTariffList: state.tariff.fetchActiveTariffName,
        getSingle: state.customer.fetchSingleCustomerGroupData,
        fetchAllRes: state.wallet.fetchAllSub,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getCustomers: () => dispatch(allCutomersAction()),
        getActiveTariffList: () => dispatch(fetchActiveTariffList()),
        singleData: (groupName) => dispatch(getSingleCustomerGroupingList(groupName)),
        fetchAll: () => dispatch(fetchAllSubscriptionApi()),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateCustomerGrouping)