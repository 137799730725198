import React from 'react'
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams, Redirect } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { allTariffAction } from "../../../../store/actions/tariffAction";
import { updateOrganisationAction, SingleOrgTariffAction, fetchRevenueShare } from "../../../../store/actions/organisationAction";
import PureBreadcrumbs from "../../breadcrums";
import "../organisation.css"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import { useTranslation } from 'react-i18next';
import { fetchAllConnectAccountList } from '../../../../store/actions/stripeAction';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { HandleKeyPress } from '../../resuableComponent/handleKeyPress';
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

const UpdateOrganisation = (props) => {
    const { t } = useTranslation();
    const { id } = useParams()
    // const [data, setData] = useState(null);
    const [address, setAddress] = useState("")
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [partnerRevenueSharing, setPartnerRevenueSharing] = useState(0)
    const [regionalRevenueSharing, setRegionalRevenueSharing] = useState(0)

    useEffect(() => {
        props.fetchRevenueShareData();
        props.all()
        props.getSingleOrgData(id);
        props.fetchAllConnectAccount();
    }, []);

    const fleetSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        address: Yup.string().required('Address is required'),
        isfleet: Yup.string().required('Fleet selection is required'),
        city: Yup.string().required('City  is required'),
        state: Yup.string().required('State/Province  is required'),
        country: Yup.string().required('Country is required'),
        status: Yup.string().required('Status is required'),
        per_unit_price: Yup.number().required('Electricity Per unit rate is required').typeError('Electricity Per unit can only be a number'),
        client_revenue_share: Yup.string().required('Client revenue share is required'),
        self_revenue_share: Yup.string().required('self_revenue_share is required'),
        regional_revenue_share: Yup.string().required('Regional revenue share is required').typeError('Regional revenue share is required'),
        Zipcode: Yup.string().required('Zip code is a required').typeError('Zip code can only be a number'),
        phone: Yup.number().required('Phone number is a required').typeError('Phone number is a required field'),
        stripe_account: Yup.string()
    });
    const { register, setValue, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(fleetSchema), mode: "all" });
    const orgdata = props.fetchSingleOrgDetails && props.fetchSingleOrgDetails.organisations[0]
    // console.log(orgdata)

    const handleAddressChange = address => {
        setAddress(address);
    };

    useEffect(() => {
        if (orgdata) {
            // console.log("orgdata.stripe_accountorgdata.stripe_account",orgdata.stripe_account)
            setTimeout(() => {
                setValue('name', orgdata.name);
                setValue('phone', orgdata.phone);
                setValue('email', orgdata.email);
                setValue('address', orgdata.address);
                setValue('isfleet', orgdata.isfleet);
                setAddress(orgdata.address);
                setValue('country', orgdata.country);
                setValue('state', orgdata.state);
                setValue('city', orgdata.city);
                setValue('status', orgdata.status);
                setValue('client_revenue_share', orgdata.client_revenue_share);
                setValue('self_revenue_share', orgdata.self_revenue_share);
                setValue('Zipcode', orgdata.postal_code);
                setValue('role', orgdata.role);
                setValue('organisation', orgdata.organisation);
                setValue('per_unit_price', orgdata.per_unit_price);
                setValue("stripe_account", orgdata.stripe_account, { shouldValidate: true });
                setValue('createdat', orgdata.createdat);
                register('createdat', orgdata.createdat);
                setValue("regional_revenue_share", orgdata.regional_revenue_share)
                setPartnerRevenueSharing(Number(orgdata.client_revenue_share))
                setRegionalRevenueSharing(Number(orgdata.regional_revenue_share))
            })
        }
    }, [orgdata, setRegionalRevenueSharing]);

    // console.log("error", errors)

    useEffect(() => {
        const calculatedResult = 100 - (partnerRevenueSharing + regionalRevenueSharing);
        setValue("self_revenue_share", calculatedResult);
    }, [partnerRevenueSharing, regionalRevenueSharing])

    // console.log("regional revenue", regionalRevenueSharing)
    // GeoLocation logic
    const handleSelect = address => {
        geocodeByAddress(address).then(results => getLatLng(results[0])).then(latLng => {
            const { lat, lng } = latLng
            const res = latiLongi(lat, lng)
            res.then(respon => {
                // const address = respon.results[0].formatted_address;
                setAddress(address)
                setValue("address", address);
                setValue("latitude", lat);
                setValue("longitude", lng);
                let city, state, country, postCode;
                for (let i = 0; i < respon.results[0].address_components.length; i++) {
                    for (let j = 0; j < respon.results[0].address_components[i].types.length; j++) {
                        switch (respon.results[0].address_components[i].types[j]) {
                            case "locality":
                                city = respon.results[0].address_components[i].long_name;
                                setValue("city", city)
                                break;
                            case "administrative_area_level_1":
                                state = respon.results[0].address_components[i].long_name;
                                setValue("state", state)
                                break;
                            case "country":
                                country = respon.results[0].address_components[i].long_name;
                                setValue("country", country)
                                break;
                            case "postal_code":
                                postCode = respon.results[0].address_components[i].long_name;
                                setValue("Zipcode", postCode)
                                break;
                            default:


                        }
                    }
                }
            })

        })
    }

    const onSubmit = async data => {
        props.updateorg(id, data)
        // console.log("save data", data)
    }

    useEffect(() => {
        if (props.updateorgg.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Partner has been updated successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.updateorgg]);

    if (redirect) {
        return <Redirect to='/dashboard/view_organisation' />;
    }

    const handlePartnerRevenueSharingChange = (e) => {
        const selectedValue = Number(e.target.value);
        // console.log("partner value: ", selectedValue)
        setPartnerRevenueSharing(selectedValue)

    };

    const handleRegionalRevenueSharingChange = (e) => {
        const selectedValue = Number(e.target.value);
        // console.log("regional head value: ", selectedValue)
        setRegionalRevenueSharing(selectedValue)
    };

    const HandleKeyPartnerPress = (e) => {
        const value = e.target.value;
        const newValue = value + e.key;

        if (e.key === '.' && value === '') {
            e.preventDefault();
        }

        if (e.key === '-' || e.key === 'e') {
            e.preventDefault();
        }

        // Prevent entering a value greater than 100
        if (parseFloat(newValue) > 100) {
            e.preventDefault();
        }
    };


    return (
        <Container fluid className="pb-4">
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}>
                </Col>
                <Col xs={12} md={10}>
                    <Card className="tariffcard" >
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("Update Organisation")}</b></Card.Text>
                        <Card.Body>
                            <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                                <div className="form-floating py-1">
                                    <label>{t("Organisation Name")} <span className='mandatory-field'>*</span></label>
                                    <input type="text" className={`form-control gray-border ${errors.name ? 'is-invalid' : ''}`} name="name" id="floatingInputGridname" placeholder={t("Organisation Name")} {...register("name")} disabled />
                                    <div className="invalid-feedback">{errors.name?.message}</div>
                                </div>

                                <div className="form-floating py-1">
                                    <label>{t("Phone No.")} <span className='mandatory-field'>*</span></label>
                                    <input type="text" className={`form-control gray-border ${errors.phone ? 'is-invalid' : ''}`} name="phone" id="floatingInputGridnumber" placeholder={t("Phone No.")} {...register("phone")} />
                                    <div className="invalid-feedback">{errors.phone?.message}</div>
                                </div>

                                <div className="form-floating py-1">
                                    <label>{t("Email")} <span className='mandatory-field'>*</span></label>
                                    <input type="text" className={`form-control gray-border ${errors.email ? 'is-invalid' : ''}`} name="email" id="floatingInputGridemail" placeholder={t("Email")} {...register("email")} />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>
                                <div className="row  py-2">
                                    <div className="col-md">
                                        <div className="form-floating">
                                            <label>{t("Enter Address")}</label>
                                            <PlacesAutocomplete
                                                value={address}
                                                onChange={handleAddressChange}
                                                onSelect={handleSelect}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div>
                                                        <input
                                                            {...getInputProps({
                                                                placeholder: 'Search Address ...',
                                                                className: "location-search-input gray-border",
                                                            })}
                                                        />

                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map(suggestion => {
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                    : { backgroundColor: '#FFFEEC ', cursor: 'pointer' };
                                                                return (
                                                                    <div className="input-suggestion"
                                                                        {...getSuggestionItemProps(suggestion, {

                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span className="material-symbols-outlined"></span><span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>

                                                    </div>
                                                )}


                                            </PlacesAutocomplete>
                                            <div className="invalid-feedback">{errors.address?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row py-1'>
                                    <div className='col-md'>
                                        <div className="form-floating">
                                            <label>{t("Is Fleet ?")} <span className='mandatory-field'>*</span></label>
                                            <select className={`form-select form-control ${errors.isfleet ? 'is-invalid' : ''}`} name="isfleet" id="floatingInputGridIsFleet" {...register("isfleet")}>
                                                <option value={""} > {t("Is Fleet ?")}</option>
                                                <option value={"Yes"} > Yes </option>
                                                <option value={"No"} > No </option>
                                            </select>
                                            <div className="invalid-feedback">{errors.isfleet?.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md">
                                        <div className="form-floating">
                                            <label>{t("Per Unit Price")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.per_unit_price ? 'is-invalid' : ''}`} name="per_unit_price" id="floatingInputUnitPrice" placeholder={t("Per Unit Price")}  {...register("per_unit_price")} />
                                            <div className="invalid-feedback">{errors.per_unit_price?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-floating">
                                            <label>{t("Select Stripe Account")}</label>
                                            <select className={`form-select  form-control ${errors.stripe_account ? 'is-invalid' : ''}`} name="stripe_account" id="floatingSelectGridstripe_account" {...register("stripe_account")}>
                                                <option value="">{t("Select Stripe Account")} </option>
                                                {props.getAllConnectAccount && props.getAllConnectAccount.data && props.getAllConnectAccount.data.map((v, k) =>
                                                    <option key={v.id} value={v.id}>{t(v.business_profile.name)}</option>
                                                )}
                                            </select>
                                            <div className="invalid-feedback">{errors.stripe_account?.message}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row ">
                                    <div className="col-md py-1">
                                        <div className="form-floating">
                                            <label>{t("Country")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.country ? 'is-invalid' : ''}`} name="country" id="floatingInputzip" placeholder={t("Country")}  {...register("country")} />
                                            <div className="invalid-feedback">{errors.country?.message}</div>
                                        </div>
                                        <div className="invalid-feedback">{errors.country?.message}</div>
                                    </div>

                                    <div className="col-md py-1">
                                        <div className="form-floating">
                                            <div className="form-floating">
                                                <label>{t("State")} <span className='mandatory-field'>*</span></label>
                                                <input type="text" className={`form-control gray-border ${errors.state ? 'is-invalid' : ''}`} name="state" id="floatingInputzip" placeholder={t("State")}  {...register("state")} />
                                                <div className="invalid-feedback">{errors.state?.message}</div>
                                            </div>

                                            <div className="invalid-feedback">{errors.state?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-md py-1">
                                        <div className="form-floating">
                                            <label>{t("City")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.city ? 'is-invalid' : ''}`} name="city" id="floatingInputzip" placeholder={t("City")}  {...register("city")} />
                                            <div className="invalid-feedback">{errors.city?.message}</div>
                                        </div>
                                    </div>
                                    <Col>
                                        <div className="form-floating">
                                            <label>{t("Zipcode")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.Zipcode ? 'is-invalid' : ''}`} name="Zipcode" id="floatingInputGridnumber" placeholder={t("Zipcode")} {...register("Zipcode")} />
                                            <div className="invalid-feedback">{errors.Zipcode?.message}</div>
                                        </div>
                                    </Col>
                                </div>

                                <Row className='mt-3'>
                                    <Col lg={3} md={12} className='col-md py-1 '>
                                        <div className="form-floating">
                                            <label>{t("Partner Revenue Sharing")} (In %) <span className='mandatory-field'>*</span></label>
                                            <input type="number"
                                                className={`form-control  gray-border ${errors.client_revenue_share ? 'is-invalid' : ''}`}
                                                name="client_revenue_share"
                                                id="floatingSelectGridClientRevenueSharing"
                                                placeholder="Enter partner revenue share"
                                                {...register("client_revenue_share")}
                                                onChange={(e) => handlePartnerRevenueSharingChange(e)}
                                                onKeyPress={HandleKeyPartnerPress}
                                            />
                                            <div className="invalid-feedback">{errors.client_revenue_share?.message}</div>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={12} className='col-md py-1'>
                                        <div className="form-floating">
                                            <label>{t("Regional Revenue Sharing")}(In %) <span className='mandatory-field'>*</span></label>
                                            <input type="number"
                                                className={`form-control  gray-border ${errors.regional_revenue_share ? 'is-invalid' : ''}`}
                                                name="regional_revenue_share"
                                                id="floatingSelectGridRegionalRevenueSharing"
                                                placeholder="Enter Regional revenue share"
                                                {...register("regional_revenue_share")}
                                                onChange={(e) => handleRegionalRevenueSharingChange(e)}
                                                onKeyPress={HandleKeyPartnerPress}

                                            />
                                            <div className="invalid-feedback">{errors.regional_revenue_share?.message}</div>
                                        </div>
                                    </Col>
                                    <br />
                                    <Col lg={3} md={12} className='col-md py-1'>
                                        <div className="form-floating mb-3">
                                            <label>{t("Self Revenue Sharing")} (In %)<span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.self_revenue_share ? 'is-invalid' : ''}`} name="self_revenue_share" id="floatingInputSelfRevenueShare" placeholder="self revenue share" {...register("self_revenue_share")} disabled />
                                        </div>
                                    </Col>
                                    <Col lg={3} md={12} className='col-md py-1'>
                                        <div className="form-floating ">
                                            <label>{t("Status")} <span className='mandatory-field'>*</span></label>
                                            <select className={`form-select form-control  ${errors.status ? 'is-invalid' : ''}`} name="status" id="floatingSelectGridS" {...register("status")}>
                                                <option value=""> {t("Status")}</option>
                                                <option value="active">Active</option>
                                                <option value="draft">Draft</option>
                                                <option value="inactive">Inactive</option>
                                            </select>
                                            {/* <label for="floatingSelectGridS">Status</label> */}
                                            <div className="invalid-feedback">{errors.status?.message}</div>
                                        </div>
                                    </Col>
                                </Row>

                                <div>
                                    <Row className='mt-3'>
                                        <Col>
                                            {/* / <button className="lgn-btn first-button" type="button" onClick={() => reset()}> Reset </button> */}
                                        </Col>
                                        <Col lg={7} md={12} sm={12} xs={12} className='d-md-flex justify-content-end'>
                                            {props.loading ?
                                                <button className="btn lgn-btn" type="button" disabled>
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;
                                                    {t("Saving...")}
                                                </button>
                                                :
                                                <button className="lgn-btn btn" type="submit" onClick={handleSubmit(onSubmit)}>{t("UPDATE ORGANISATION")}</button>
                                            }
                                        </Col>
                                    </Row>

                                </div>
                            </form>
                        </Card.Body>
                        <br /><br />
                    </Card>

                </Col>
                <Col xs={12} md={2}>
                </Col>

            </Row>
        </Container>


    )
}



const mapStateToProps = (state) => {
    return {
        loading: state.operator.isLoading,
        cities: state.main.cities,
        states: state.main.states,
        countries: state.main.countries,
        tarifflist: state.tariff.list,
        fetchSingleOrgDetails: state.operator.singleOrgTariff,
        updateorgg: state.operator.updateorg,
        revenue_share: state.operator.revenueShare,
        getAllConnectAccount: state.stripe.getAllConnectAcount,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(allTariffAction()),
        getSingleOrgData: (id) => dispatch(SingleOrgTariffAction(id)),
        updateorg: (id, org) => dispatch(updateOrganisationAction(id, org)),
        fetchRevenueShareData: () => dispatch(fetchRevenueShare()),
        fetchAllConnectAccount: () => dispatch(fetchAllConnectAccountList()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrganisation)


const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}