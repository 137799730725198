// import react from 'react';
import { connect } from 'react-redux';
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { fetchAllStateGroup, stateApi, updateStateGroupApi } from '../../../../store/actions/accessAction';
import PureBreadcrumbs from "../../breadcrums";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { Bounce, ToastContainer, toast } from 'react-toastify';




const UpdateStateGroup = (props) => {
    const { t } = useTranslation();
    const countryCode = process.env.REACT_APP_COUNTRY_CODE;
    const [isArrayMatch, setIsArrayMatch] = useState(false);
    const [isZoneMatch, setIsZoneMatch] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.getState(countryCode);
        props.getStateView();
    }, [])

    const prevdata = props.allStateRes && props.allStateRes.data
    // console.log("prevdata", prevdata);

    const transformedData = {
        stateGroup: Object.entries(prevdata && prevdata[0] && prevdata[0]).flatMap(([key, value]) => {
            const zone = key.charAt(0).toUpperCase() + key.slice(1);
            const states = Array.isArray(value) ? value.map(state => state.charAt(0).toUpperCase() + state.slice(1)) : [value];
            return {
                zone,
                states
            }
        }).filter(item => item.zone !== 'Role' && item.zone !== 'Organisation')
    };

    const filteredStateGroup = transformedData && transformedData.stateGroup?.filter(group => group.states.some(state => state !== null && state !== ''));
    // console.log(filteredStateGroup);

    const state = props.stateRes && props.stateRes.state
    // console.log(state);

    const stateSchema = Yup.object().shape({
        stateGroup: Yup.array().of(
            Yup.object().shape({
                zone: Yup.string().required("Zone is required"),
                states: Yup.array().of(Yup.string())
            })
        ),
    });


    const { register, control, getValues, setValue, watch, handleSubmit, reset, formState: { errors, isValid }, } = useForm({
        resolver: yupResolver(stateSchema), mode: "all",
    });
    const { fields: fieldsList1, remove: removeList1, append: appendList1, } = useFieldArray({ control, name: "stateGroup" });
    const stateGroupWatch = watch("stateGroup");


    useEffect(() => {
        if (prevdata[0]) {
            setValue("stateGroup", filteredStateGroup)
        }
    }, [prevdata])

    let statesOnly = stateGroupWatch && stateGroupWatch.map(item => item.states).flat();
    let zoneOnly = stateGroupWatch && stateGroupWatch.map(item => item.zone).flat();

    const onSubmit = async (data) => {
        props.updateGrouping(data);
        // console.log(data)
    };
    useEffect(() => {
        if (props.updateStateGroupRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('State group is update successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.updateStateGroupRes]);

    if (redirect) {
        return <Redirect to={'/dashboard/view_state_group'} />
    }

    // if (props.updateStateGroupRes.statuscode === 200) {
    //     alert("State group is update successfully");
    //     return <Redirect to={'/dashboard/view_state_group'} />
    // }

    const options = state && state.map(state => ({ value: state.state_name, label: state.state_name }));
    // console.log("option", options)

    const handleDayChange = (e) => {
        const value = e.target.value;
        const isPresent = zoneOnly.includes(value);
        setIsZoneMatch(isPresent)
    }

    const handleRegionOnchange = (selectedOption, i) => {

        if (selectedOption) {
            const selectedValues = selectedOption.map(option => option.value);
            setValue(`stateGroup[${i}].states`, selectedValues)
            const isPresent = selectedValues.every(state => statesOnly.includes(state));
            setIsArrayMatch(isPresent)
        }
    };


    return (

        <>
            <div className="container-fluid">
                {isToastVisible && <div className="overlay" />}
                <ToastContainer
                    position="top-center"
                    autoClose={20}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    style={{ top: "10%", width: "auto", zIndex: "9999" }}
                />
                <PureBreadcrumbs />
                <Row className="d-flex justify-content-center align-items-center pt-5">
                    <Col xs={12} md={12} lg={12}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div>
                                <Card className="d-flex justify-content-center align-items-center">
                                    <Card.Body>
                                        <h6 className="text-center"> {t("Update Zone & State Groups")}</h6>
                                    </Card.Body>
                                </Card>

                                {fieldsList1.map((item, i) => (
                                    <Card className="tariff-card" key={i}>
                                        {i === 0 ? (
                                            <h6 className="text-start p-3">
                                                <b>{t("Update Zone & States")}</b>
                                            </h6>
                                        ) : (
                                            ""
                                        )}
                                        <Card.Body>
                                            <div className="row g-2 mt-3 ">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>
                                                            {t("Select Zones")}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <select
                                                            className={`form-select form-control ${errors.stateGroup?.[i]?.zone ? "is-invalid" : ""
                                                                }`}
                                                            name={`stateGroup[${i}].zone`}
                                                            id="floatingSelectGridday"
                                                            {...register(`stateGroup[${i}].zone`)}
                                                            onChange={(e) => handleDayChange(e, i)}
                                                        >
                                                            <option value={""}>{t("Select Zones")}</option>
                                                            {<option value={"North"}>{t("North Zone")}</option>}
                                                            {<option value={"South"}>{t("South Zone")}</option>}
                                                            {<option value={"East"}>{t("East Zones")}</option>}
                                                            {<option value={"West"}>{t("West Zones")}</option>}
                                                        </select>
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        {errors.stateGroup?.[i]?.zone?.message}
                                                    </div>
                                                    {isZoneMatch && <div className="text-danger">
                                                        {t("Same Zone already exist")}
                                                    </div>}

                                                </div>

                                                <div className='col-md'>
                                                    <label>
                                                        {t("Select States")}
                                                        <span className="mandatory-field">*</span> &nbsp;
                                                        {/* <span className="text-success">{item.states.join(", ")}</span> */}
                                                    </label>
                                                    <div className='form-floating'>
                                                        <Select
                                                            placeholder="select States"
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            defaultValue={item?.states?.map(region => ({ value: region, label: region }))}
                                                            options={options}
                                                            onChange={(selectedOption) => handleRegionOnchange(selectedOption, i)}
                                                            noOptionsMessage={() => "No state is available"}
                                                        />
                                                    </div>
                                                    {isArrayMatch && <div className="text-danger">
                                                        {t("Same States already exist")}
                                                    </div>}
                                                </div>
                                            </div>
                                        </Card.Body>
                                        <div className="d-grid gap-2 d-flex justify-content-end p-1">
                                            <button
                                                className="btn  btn-lg"
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    removeList1(i);
                                                    setIsZoneMatch(false)
                                                    setIsArrayMatch(false)
                                                }}
                                            >
                                                {" "}
                                                <i
                                                    className="far fa-trash-alt"
                                                    style={{ color: "red" }}
                                                ></i>
                                            </button>
                                        </div>
                                    </Card>
                                ))}

                                <Card.Footer className="" >
                                    <Row className={!isZoneMatch ? (stateGroupWatch && stateGroupWatch.length > 3 ? "d-flex justify-content-end" : "d-flex justify-content-between") : ("d-flex justify-content-end")}>
                                        {stateGroupWatch && stateGroupWatch.length <= 3 && !isZoneMatch &&
                                            <Col lg={6} md={6} className="d-flex justify-content-center justify-content-md-start mt-2">
                                                <button
                                                    className="btn btn-dark"
                                                    type="button"
                                                    onClick={() =>
                                                        appendList1({
                                                            zone: "",
                                                            states: [""]
                                                        })
                                                    }
                                                >
                                                    (+) {t("Update More Zones")}
                                                </button>

                                            </Col>}
                                        {
                                            stateGroupWatch &&
                                                stateGroupWatch.length > 0 &&
                                                props.loading ?
                                                (
                                                    <Col lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-end mt-2">
                                                        <button className="btn lgn-btn" type="button" disabled>
                                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                            {t("Saving...")}
                                                        </button>
                                                    </Col>
                                                )
                                                :
                                                (
                                                    <Col lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center justify-content-md-end mt-2">
                                                        <button
                                                            className="lgn-btn btn"
                                                            type="submit"
                                                            onClick={handleSubmit(onSubmit)}
                                                            disabled={isZoneMatch}
                                                        >
                                                            {t("Update")}
                                                        </button>
                                                    </Col>
                                                )}
                                    </Row>
                                </Card.Footer>
                            </div>
                        </form>
                    </Col>
                </Row>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.access.isLoading,
        updateStateGroupRes: state.access.updateState,
        stateRes: state.access.getState,
        allStateRes: state.access.fetchAllStateGroups,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateGrouping: (data) => dispatch(updateStateGroupApi(data)),
        getState: (code) => dispatch(stateApi(code)),
        getStateView: (code) => dispatch(fetchAllStateGroup(code)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateStateGroup);