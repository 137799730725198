import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect } from "react";
import RFIDList from "./rfidList";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { allRFIDAction } from "../../../../store/actions/customerActions";
import DownloadRfid from "./downloadRfid";

function ViewRFID(props) {
  const { t } = useTranslation();

  useEffect(() => {
    props.all();
  }, []);

  const data = props.list && props.list.rfids;
  // console.log(data);


  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />
      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <Row className="table-header">
              <Col lg={6} className="table-header">
                {t("RFID List")}
              </Col>
              <Col lg={6} className="d-flex justify-content-end">
                <DownloadRfid {...props} data={data} />
              </Col>
            </Row>
            <RFIDList></RFIDList>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.customer.isLoading,
    list: state.customer.fetchAllRFID,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allRFIDAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewRFID);
