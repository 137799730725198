import { OCPI_URL, SAVE_ROAMING_PARTNER, VIEW_ALL_ROAMING_PARTNER, FETCH_PARTNER_TARIFF_BY_ID_API, VIEW_SINGLE_ROAMING_PARTNER, UPDATE_ROAMING_PARTNER, FETCH_CONSUMED_LOCATION_API, FETCH_SINGLE_CONSUMED_LOCATION_API, ACTIVATE_ROAMING_API, DEACTIVATE_ROAMING_API, FETCH_PARTNER_TARIFF_API, FETCH_PARTNER_PARTY_ID_API, REFRESH_PARTNER_STATION_API, REFRESH_PARTNER_TARIFF_API, GENERATE_TOKEN_API, FETCH_OCPI_STATION_LIST, FETCH_SINGLE_OCPI_STATION_LIST, FETCH_EXTERNAL_TRANSACTION_LIST_API, FETCH_INTERNAL_TRANSACTION_LIST_API } from "../../components/config/config";

export const saveRoamingPartner = (data) => {
    const { partner_name, party_id, url, token_a } = data
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(data)
    const bearer = 'base ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + SAVE_ROAMING_PARTNER, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "partner_name": partner_name,
                'url': url,
                "party_id": party_id,
                // 'role': role,
                // "organisation": organisation,
                "token_a": token_a
            })
        }).then(resp => resp.json()).then(data => {
            console.log("SAVE_ROAMING_PARTNER", data)
            dispatch({ type: 'SAVE_ROAMING_PARTNER', payload: data })
        })
    }
}

export const allRoamingPartner = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + VIEW_ALL_ROAMING_PARTNER + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'VIEW_ALL_ROAMING_PARTNER', payload: data })
        })
    }
}


export const allRoamingPartnerPartnerID = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_PARTNER_PARTY_ID_API, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'FETCH_PARTNER_ID', payload: data })
        })
    }
}

export const singleRoamingPartner = (party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    // console.log("efEEWFEWFEW", OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id)
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + VIEW_SINGLE_ROAMING_PARTNER + "?party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("VIEW_SINGLE_ROAMING_PARTNER", data)
            dispatch({ type: 'VIEW_SINGLE_ROAMING_PARTNER', payload: data })
        })
    }
}

export const updateRoamingPartner = (id, data) => {
    const { partner_name, country_code, address, city, state, country, party_id, url, token_a } = data
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + UPDATE_ROAMING_PARTNER, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "partner_name": partner_name,
                'country_code': country_code,
                'address': address,
                "city": city,
                "state": state,
                'country': country,
                'url': url,
                "party_id": party_id,
                'role': role,
                "organisation": organisation,
                "tokenA": token_a

            })
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'UPDATE_ROAMING_PARTNER', payload: data })
        })
    }
}

export const fetchLocationbyPartyId = (partner_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_CONSUMED_LOCATION_API + "?party_id=" + partner_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'VIEW_LOCATION_BY_PARTY_ID', payload: data })
        })
    }
}

export const viewSingleConsumedStation = (name, party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    console.log("EWfew", OCPI_URL + FETCH_SINGLE_CONSUMED_LOCATION_API + "?location_id=" + name + "&party_id=" + party_id)
    console.log("WrfwefF", token)
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_SINGLE_CONSUMED_LOCATION_API + "?location_id=" + name + "&party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            console.log("rjkbvkrjbgkjwebfew,data", data)
            dispatch({ type: 'FETCH_SINGLE_CONSUMED_LOCATION', payload: data })
        })
    }
}

export const activateRoamingPartner = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch("http://192.168.1.17:7041/ocpi/secure/api/v1/activate/roamingpartner?id=6596a1744705d79425d6f19b", {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'ACTIVATE_ROAMING_PARTNER', payload: data })
        })
    }
}

export const deactivateRoamingPartner = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch("http://192.168.1.17:7041/ocpi/secure/api/v1/deactivate/roamingpartner?id=6596a1744705d79425d6f19b", {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'DEACTIVATE_ROAMING_PARTNER', payload: data })
        })
    }
}

export const fetchAllPartnerTariff = (party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_PARTNER_TARIFF_API + "?party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("webfwvufvewufbvew", data)
            dispatch({ type: 'FETCH_PARTNER_TARIFF', payload: data })
        })
    }
}

export const viewSingleConsumedTariff = (id, party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_PARTNER_TARIFF_BY_ID_API + "?id=" + id + "&party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("wbfjkwbfujkwebfwke",data)
            dispatch({ type: 'FETCH_PARTNER_TARIFF_BY_ID', payload: data })
        })
    }
}

export const refreshPartnerStationAction = (party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    console.log("OCPI_URL + REFRESH_PARTNER_STATIONrty_id", OCPI_URL + REFRESH_PARTNER_STATION_API + "?party_id=" + party_id)
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + REFRESH_PARTNER_STATION_API + "?party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            console.log("wbfjkwbfujkwebfwke", data)
            dispatch({ type: 'REFRESH_PARTNER_STATION_BY_ID', payload: data })
        })
    }
}
export const refreshPartnerTariffAction = (party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + REFRESH_PARTNER_TARIFF_API + "?party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("wbfjkwbfujkwebfwke",data)
            dispatch({ type: 'REFRESH_PARTNER_TARIFF_BY_ID', payload: data })
        })
    }
}

export const generateTokenAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + GENERATE_TOKEN_API, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("wbfjkwbfujkwebfwke",data)
            dispatch({ type: 'GENERATE_TOKEN', payload: data })
        })
    }
}

export const fetchOcpiStationAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_OCPI_STATION_LIST, {
            // return fetch("http://192.168.1.20:7041/ocpi/secure/api/v1/fetch/locations", {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("FETCH_OCPI_STATION_API", data)
            dispatch({ type: 'FETCH_OCPI_STATION_API', payload: data })
        })
    }
}
export const fetchSingleOcpiStationAction = (name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_SINGLE_OCPI_STATION_LIST + "?name=" + name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("FETCH_SINGLE_OCPI_STATION_API", data)
            dispatch({ type: 'FETCH_SINGLE_OCPI_STATION_API', payload: data })
        })
    }
}

export const fetchExternalTransactionListAction = (party_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    console.log(OCPI_URL + FETCH_EXTERNAL_TRANSACTION_LIST_API + "?party_id=" + party_id)
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_EXTERNAL_TRANSACTION_LIST_API + "?party_id=" + party_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            console.log("FETCH_EXTERNAL_TRANSACTION_LIST", data)
            dispatch({ type: 'FETCH_EXTERNAL_TRANSACTION_LIST', payload: data })
        })
    }
}

export const fetchInternalTransactionListAction = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    console.log(OCPI_URL + FETCH_INTERNAL_TRANSACTION_LIST_API + "?status=" + "All")
    return dispatch => {
        dispatch({ type: 'OCPI_LOADING', payload: '' })
        return fetch(OCPI_URL + FETCH_INTERNAL_TRANSACTION_LIST_API + "?status=" + "All", {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            console.log("FETCH_INTERNAL_TRANSACTION_LIST", data)
            dispatch({ type: 'FETCH_INTERNAL_TRANSACTION_LIST', payload: data })
        })
    }
}