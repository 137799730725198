import React from 'react'
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { allCorporateCodeAction, allOrgTariffAction, updateCorporateCode } from "../../../../store/actions/organisationAction";
import PureBreadcrumbs from "../../breadcrums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../organisation.css"
import { fetchActiveTariffList } from "../../../../store/actions/tariffAction";
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import Select from "react-select"

const UpdateCorporateCode = (props) => {
    const { t } = useTranslation();
    const { id } = useParams()
    // console.log("id for update", id)
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.getActiveTariffList()
        props.allOrg()
        props.all();
    }, []);

    const data = props.allcorporatecode && props.allcorporatecode.code.filter((item) => item.id === id);
    // console.log(data);
    const tariffList = props.fetchActiveTariffList && props.fetchActiveTariffList.data
    // console.log(tariffList)
    const previousStartDate = data && data[0]?.startdate;
    const previousEndDate = data && data[0]?.enddate;
    // console.log("previousEndDate",previousEndDate)
    const PrevStartDate = previousStartDate ? new Date(previousStartDate) : null;
    const PrevEndDate = previousEndDate ? new Date(previousEndDate) : null;
    // console.log("PrevStartDate", PrevStartDate)s
    // console.log("PrevEndDate", PrevEndDate)


    const promotionSchema = Yup.object().shape({
        corporate_code: Yup.string().required('Corporate Code is required'),
        code_name: Yup.string().required('Corporate code name is required'),
        tariff: Yup.string().required('Tariff is required'),
        assignedoperator: Yup.string().required('Organisation is required'),

    });

    const [startdate, setstartdate] = useState(null);
    const [enddate, setenddate] = useState(null);
    // console.log("startdate",startdate)
    // console.log("enddate",enddate)

    const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm({ resolver: yupResolver(promotionSchema) });

    useEffect(() => {
        if (data[0]) {

            setValue('corporate_code', data[0].corporate_code);
            setValue('code_name', data[0].code_name);
            setValue('tariff', data[0].tariff);
            setValue('assignedoperator', data[0].assignedoperator);
        }
    }, [data]);



    const onSubmit = async (data) => {
        if (startdate === null && enddate === null) {
            // console.log("data saved with both previous date successfully",data,PrevStartDate, PrevEndDate);
            props.updateCorpCode(data, PrevStartDate, PrevEndDate)
        } else if (startdate !== null && enddate === null) {
            // console.log("data saved with  startdate, PrevEndDate successfully",data,startdate, PrevEndDate);
            props.updateCorpCode(data, startdate, PrevEndDate)
        } else if (startdate === null && enddate !== null) {
            // console.log("data saved with  PrevStartDate, enddate successfully",data,PrevStartDate, enddate);
            props.updateCorpCode(data, PrevStartDate, enddate)
        }
        else if (PrevStartDate !== null && PrevEndDate !== null) {
            // console.log("data saved with both selected date successfully",data,startdate, enddate);     
            props.updateCorpCode(data, startdate, enddate)
        }
    };

    useEffect(() => {
        if (props.updateCorp.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Corporate Code has been Updated successfully!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.updateCorp]);

    if (redirect) {
        return <Redirect to='/dashboard/view_corporate_code' />;
    }

    const handleTariff = (e) => {
        const code = e.value;
        // console.log("currency", code);
        setValue("tariff", code);
    };

    const tariffOptions = props.fetchActiveTariffList && props.fetchActiveTariffList.data?.map(item => ({
        value: item.name,
        label: item.name
    }));


    const handleOrganisation = (e) => {
        const code = e.value;
        // console.log("currency", code);
        setValue("assignedoperator", code);
    };

    const organisationOptions = props.organisationlist && props.organisationlist.organisations?.map(item => ({
        value: item.name,
        label: item.name
    }));

    return (
        <Container fluid className="pb-4">
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}>
                </Col>
                <Col xs={12} md={10}>
                    <Card className="tariffcard" >
                        <Card.Text style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}><b>{t("Update Corporate Code")}</b></Card.Text>
                        <Card.Body>
                            <form autoComplete='off' onSubmit={e => e.preventDefault()}>
                                <Row>
                                    <Col lg={6} >
                                        <div className="form-floating">
                                            <label>{t("Enter the Corporate Code")}  <span className='mandatory-field'>*</span></label>
                                            <input disabled type="text" className={`form-control gray-border ${errors.corporate_code ? 'is-invalid' : ''}`} name="corporate_code" id="floatingInputGridcorporate_code" placeholder={t("Enter the Corporate Code")} {...register("corporate_code")} />
                                            <div className="invalid-feedback">{errors.corporate_code?.message}</div>
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <div className="form-floating">
                                            <label>{t("Enter specific corporate code name")}  <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.code_name ? 'is-invalid' : ''}`} name="code_name" id="floatingInputGridnumber" placeholder={t("Enter specific corporate code name")}  {...register("code_name")} />
                                            <div className="invalid-feedback">{errors.code_name?.message}</div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col className="text-center">
                                        {t("Select Corporate code Start Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            className='gray-border'
                                            selected={startdate || PrevStartDate}
                                            name="start"
                                            onChange={(date) => setstartdate(date)}
                                            selectsStart
                                            startdate={startdate || PrevStartDate}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </Col>
                                </Row><br />
                                <Row>
                                    <Col className="text-center">
                                        {t("Select Corporate code End Date")} :
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            className='gray-border'
                                            selected={enddate || PrevEndDate}
                                            onChange={(date) => setenddate(date)}
                                            selectsEnd
                                            startdate={startdate || PrevStartDate}
                                            enddate={enddate || PrevEndDate}
                                            minDate={startdate || PrevStartDate}
                                            showDisabledMonthNavigation
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </Col>
                                </Row><br />

                                <Row>
                                    <Col>
                                        <div className="row g-2 py-2">
                                            <div className="col-md">
                                                <div className="form-floating">
                                                    <label>{t("Select Tariff")}  <span className='mandatory-field'>*</span></label>
                                                    <Select
                                                        options={tariffOptions ? tariffOptions : []}
                                                        className={`${errors.tariff ? 'is-invalid' : ''}`}
                                                        name="tariff"
                                                        id="floatingInputGridTariff"
                                                        placeholder={getValues("tariff") ? getValues("tariff") : "Select Tariff"}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? 'grey' : 'grey',
                                                                boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                                height: 'calc(1.5em + 0.75rem + 2px)',
                                                                minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                                            }),
                                                            valueContainer: (baseStyles) => ({
                                                                ...baseStyles,
                                                                height: 'calc(1.5em + 0.75rem + 2px)',
                                                                padding: '0 0.75rem',
                                                            }),
                                                            placeholder: (baseStyles) => ({
                                                                ...baseStyles,
                                                                whiteSpace: 'nowrap',
                                                            }),
                                                            input: base => ({
                                                                ...base,
                                                                border: 'none',
                                                                boxShadow: 'none',
                                                                '& input': {
                                                                    border: 'none !important',
                                                                },
                                                            }),

                                                        }}
                                                        onChange={handleTariff}
                                                    />
                                                    <div className="invalid-feedback">{errors.tariff?.message}</div>
                                                </div>
                                            </div><br /><br />
                                            <div className="col-md">
                                                <div className="form-floating">
                                                    <label>{t("Select Organisation")}  <span className='mandatory-field'>*</span></label>
                                                    <Select
                                                        options={organisationOptions ? organisationOptions : []}
                                                        className={`${errors.assignedoperator ? 'is-invalid' : ''}`}
                                                        name="assignedoperator"
                                                        id="floatingInputGridassignedoperator"
                                                        placeholder={getValues("assignedoperator") ? getValues("assignedoperator") : "Select Organisation"}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? 'grey' : 'grey',
                                                                boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                                height: 'calc(1.5em + 0.75rem + 2px)',
                                                                minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                                            }),
                                                            valueContainer: (baseStyles) => ({
                                                                ...baseStyles,
                                                                height: 'calc(1.5em + 0.75rem + 2px)',
                                                                padding: '0 0.75rem',
                                                            }),
                                                            placeholder: (baseStyles) => ({
                                                                ...baseStyles,
                                                                whiteSpace: 'nowrap',
                                                            }),
                                                            input: base => ({
                                                                ...base,
                                                                border: 'none',
                                                                boxShadow: 'none',
                                                                '& input': {
                                                                    border: 'none !important',
                                                                },
                                                            }),

                                                        }}
                                                        onChange={handleOrganisation}
                                                    />
                                                    <div className="invalid-feedback">{errors.assignedoperator?.message}</div>
                                                </div>
                                            </div>
                                        </div><br />
                                    </Col>
                                </Row>

                                <Row className="mt-3 d-flex justify-content-end">
                                    <Col lg={6} className='d-flex justify-content-end'>
                                        {props.loading ?
                                            <button className="btn lgn-btn" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;
                                                {t("Saving...")}
                                            </button>
                                            :
                                            <button className="btn lgn-btn" type="submit" onClick={handleSubmit(onSubmit)}>{t("UPDATE CORPORATE CODE")}</button>
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                        <br /><br />
                    </Card>

                </Col>
                <Col xs={12} md={1}>
                </Col>

            </Row>
        </Container>


    )
}



const mapStateToProps = (state) => {
    return {
        loading: state.operator.isLoading,
        allcorporatecode: state.operator.allcorporatecode,
        fetchActiveTariffList: state.tariff.fetchActiveTariffName,
        savecorporate: state.operator.savecorporatecode,
        organisationlist: state.operator.organisationsList,
        updateCorp: state.operator.updateCorpCode,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(allCorporateCodeAction()),
        getActiveTariffList: () => dispatch(fetchActiveTariffList()),
        allOrg: () => dispatch(allOrgTariffAction()),
        updateCorpCode: (data, startdate, enddate) => dispatch(updateCorporateCode(data, startdate, enddate))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateCorporateCode)
