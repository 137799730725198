import React from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import { Card, Col, Container, Row } from "react-bootstrap";
import { generateTokenAction } from "../../../../store/actions/ocpiAction";


const GenerateOCPIToken = (props) => {

    const handleAddClick = () => {
        props.generateToken()
    };

    const data = props.tokenGeneration && props.tokenGeneration ? props.tokenGeneration : {};

    return (
        <Container fluid >
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col>
                    <Card className="tariffcard">
                        <Row>
                            <Col xs={6} style={{ padding: "10px 15px", textAlign: "left" }}>
                                <Card.Text style={{ fontSize: "14px" }}><b>Generate token for CPOs</b></Card.Text>
                            </Col>
                            <Col xs={6} style={{ padding: "10px 15px", textAlign: "right" }}>
                                <Card.Text style={{ fontSize: "14px" }}><b>
                                    <a className="refresh" style={{ fontSize: "14px", color: "var(--button-color)", fontWeight: "bold", cursor: "pointer" }} onClick={handleAddClick} >Click here to generate &nbsp;<img style={{ height: "16px" }} src={process.env.PUBLIC_URL + '/images/insight/refresh.svg'} alt="" />
                                    </a>
                                </b></Card.Text>
                            </Col>
                        </Row>
                        <Card.Body>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputGridname'>Party ID</label>
                                        <input type="text" className={`form-control gray-border `} name="party_id" id="floatingInputGridparty_id" placeholder="Party ID" value={data?.party_id || 'Not Available'} disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputGridname'>Version API</label>
                                        <input type="text" className={`form-control gray-border `} name="url" id="floatingInputGridurl" placeholder="URL" value={data?.url || 'Not Available'} disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col-md p-2">
                                    <div className="form-floating">
                                        <label htmlFor='floatingInputGridname'>Token</label>
                                        <input type="text" className={`form-control gray-border `} name="url" id="floatingInputGridurl" placeholder="URL" value={data?.token || 'Not Available'} disabled />
                                    </div>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    );

}
const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        tokenGeneration: state.ocpi.generateToken

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        generateToken: () => dispatch(generateTokenAction()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(GenerateOCPIToken);

