import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import { fetchCountryCode } from '../store/actions/insightAction';

function LanguageSwitcher(props) {
    const { i18n } = useTranslation();
    // console.log(props)
    useEffect(() => {
        props.getCC()
    }, [])

    const data = props.getCode && props.getCode?.country;
    // console.log(props.getCode && props.getCode?.country)

    const languagePlaceholderMap = data && data.reduce((acum, lang) => {
        // console.log(acum);
        acum[lang.code] = { label: lang.name, icon: <img src={lang.icon} alt={lang.code} style={{ width: '15px', height: '15px' }} /> };
        return acum;
    }, {});

    const placeholderObj = languagePlaceholderMap ? languagePlaceholderMap[i18n.language] || languagePlaceholderMap.en : null;
    // const userLanguage = window.navigator.languages;
    // console.log(languagePlaceholderMap)
    const customPlaceholder = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {placeholderObj && placeholderObj.icon}
            <span style={{ marginLeft: 5 }}>{placeholderObj && placeholderObj.label}</span>
        </div>
    );

    const handleChangeLanguage = (e) => {
        const selectedLanguage = e.code;
        // console.log(selectedLanguage)
        localStorage.setItem("LocalLanguage", selectedLanguage)
        props.onChangeLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
    };
    return (
        <Select
            className='form-select country_select'
            placeholder={customPlaceholder}
            options={props.getCode && props.getCode?.country}
            onChange={handleChangeLanguage}
            isSearchable={false}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    backgroundColor: 'white', // Set the background color of the control
                    borderColor: state.isFocused ? 'white' : 'white', // Set border color on focus
                    '&:hover': {
                        borderColor: state.isFocused ? 'white' : 'white', // Set border color on hover
                    },
                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? 'white' : 'white', // Set background color of the option
                    color: state.isSelected ? 'black' : 'black', // Set text color of the option
                    '&:active': {
                        backgroundColor: 'white', // Set background color on active state
                        color: 'black', // Set text color on active state
                    },
                }),
            }}
            formatOptionLabel={item => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={`${item.icon}`} alt={item.code} style={{ width: '15px', height: '15px' }} />
                    <span style={{ marginLeft: 5 }} >{item.name}</span>
                </div>
            )}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.insight.isLoading,
        getCode: state.insight.getCountryCode,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getCC: () => dispatch(fetchCountryCode()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);

// export default LanguageSwitcher;
